<template>
	<div class="mail_form">
		<form id="inquiry_form"></form>
		<table class="form_table">
			<tbody>
				<tr v-for="(data,i) in formData" :class="[data.key,{warning:data.warning}]" :key="'form_table' + i">
					<th><span>{{data.title}}<span v-if="data.required">（必須）</span></span></th>
					<td>
						<div v-if="data.key === 'content'">
							<textarea v-model="data.value" :name="data.key" form="inquiry_form" @input="checkForm(data)"></textarea>
						</div>
						<div v-else>
							<input v-model="data.value" type="text" :name="data.key" form="inquiry_form" @input="checkForm(data)">
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<div class="btn" @click="confirmContents">入力内容の確認</div>

		<modal v-if="checkModalState('confirm')" id="confirmModal" @hide="hideModal">
			<template v-slot:header>入力内容の確認</template>
			<template v-slot:body>
				<table class="confirm_table">
					<tbody>
						<tr v-for="(data,i) in formData" :class="data.key" :key="'confirm_table' + i">
							<th><span>{{data.title}}<span v-if="data.required">（必須）</span></span></th>
							<td><div :class="data.key">{{data.value}}</div></td>
						</tr>
					</tbody>
				</table>
			</template>
			<template v-slot:footer>
				<div class="btn_wrap">
					<div class="btn" @click="hideModal">キャンセル</div>
					<div class="btn" @click="send">送信</div>
				</div>
			</template>
		</modal>

		<modal v-if="checkModalState('sending')" id="confirmModal" @hide="hideModal">
			<template v-slot:header>送信中</template>
			<template v-slot:body>
				<div><loading/></div>
			</template>
			<template v-slot:footer><div></div></template>
		</modal>

		<modal v-if="checkModalState('sended')" id="confirmModal" @hide="hideModal">
			<template v-slot:header>送信完了</template>
			<template v-slot:body>
				<div>
					お問い合わせ頂き誠にありがとうございました。<br>
					お問い合わせ内容を確認させていただき、後ほど担当者よりご回答をさせていただきます。<br>
					恐れ入りますが、今しばらくお待ちいただけますよう、宜しくお願い申し上げます。
				</div>
			</template>
			<template v-slot:footer>
				<div class="btn_wrap">
					<div class="btn" @click="hideModal">OK</div>
				</div>
			</template>
		</modal>

		<modal v-if="checkModalState('error')" id="confirmModal" @hide="hideModal">
			<template v-slot:header class="error">送信エラー</template>
			<template v-slot:body>
				<div class="error">
					送信中にエラーが発生しました。<br>
					恐れ入りますが、もう一度送信していただくか、<br>
					お電話にてご連絡ください。
				</div>
			</template>
			<template v-slot:footer>
				<div class="btn_wrap">
					<div class="btn" @click="hideModal">キャンセル</div>
					<div class="btn" @click="send">再送信</div>
				</div>
			</template>
		</modal>

	</div>
</template>

<script>
import axios from 'axios';
import checkMail from './checkMail.js';
import modal from './modal.vue';
import loading from './loading.vue';

export default {
	name:'formTable',
	data:function(){
		return {
			formData:[
				{key:'name',title:'お名前',value:'',required:true,max:100,warning:false},
				{key:'kana',title:'フリガナ',value:'',required:false,max:100,warning:false},
				{key:'mail',title:'ご連絡先メールアドレス',value:'',required:true,max:100,warning:false},
				{key:'tel',title:'ご連絡先お電話番号',value:'',required:true,max:20,warning:false},
				{key:'content',title:'ご質問内容',value:'',required:true,max:2000,warning:false},
			],
			state:false,
			ajaxType:'inquiry_form',
			modalState:false,
			invalid:false,
			checkless:false,
		}
	},
	components:{
		modal:modal,
		loading:loading
	},
	props:['id','token'],
	methods:{
		confirmContents:function(){
			if(!this.checkless && !this.preSendCheck()) return;
			this.showModal('confirm');
		},
		showModal:function(state){
			this.modalState = state;
		},
		hideModal:function(){
			this.modalState = false;
		},
		checkModalState:function(state){
			return (this.modalState && this.modalState === state);
		},
		preSendCheck:function(){
			let err = false;
			const blanks = this.formData.filter((item) => {
				return item.value === '' && item.required;
			});
			if(blanks.length){
				alert('必須項目は全て記入してください。');
				blanks.forEach((item) => {
					item.warning = true;
				});
				err = true;
			}

			const errorMail = this.formData.filter(item => item.key === 'mail')[0];
			if(!checkMail(errorMail.value)){
				alert('メールアドレスが正しくありません。送信可能なメールアドレスをご記入ください。')
				errorMail.warning = true;
				err = true;
			}

			const overStrLength = this.formData.filter((item) => {
				return item.value.length > item.max;
			});
			if(overStrLength.length){
				overStrLength.forEach((item) => {
					alert('文字数オーバーです。' + item.title + '：' + item.max + '文字以下');
					item.warning = true;
				});
				err = true;
			}

			return err ? false : true;
		},
		checkForm:function(data){
			if(!data.warning) return;
			if(data.value !== '') data.warning = false;
		},
		createSendData:function(){
			const sendData = {};
			this.formData.forEach((v) => {
				sendData[v.key] = v.value;
			});
			const params = new URLSearchParams();
			params.set('token',this.token);
			params.set('ajaxType',this.ajaxType);
			params.set('data',JSON.stringify(sendData));
			return params;
		},
		send:function(){
			if(this.invalid){
				alert('invalid');
				return;
			}
			const axiosPost = axios.create({
				xsrfHeaderName: 'X-CSRF-Token',
				withCredentials: true
			});

			axiosPost.interceptors.request.use(config => {
				config.headers = {
					'Csrf-Token': this.token,
				};
				return config;
			});

			this.showModal('sending');
			axiosPost.post('/ajax/ajax.php', this.createSendData())
			.then(response => {
				this.resSuccess(response.data);
			}).catch(error => {
				this.resError(error);
			});
		},
		resSuccess:function(data){
			if(!data.data){
				this.showModal('error');
				console.log(data);
				return;
			}
			this.showModal('sended');
			this.resetForm();
		},
		resError:function(error){
			console.log(error);
			this.showModal('error');
		},
		resetForm:function(){
			this.formData.forEach(f => {
				f.value = '';
				f.warning = false;
			});
		},
		test:function(){
		}
	},
};
</script>
